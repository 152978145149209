import React from 'react';
import Styles from './gridItem.module.css';
import ClickNHold from 'react-click-n-hold';
import gsap from 'gsap';

class GridItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoPlaying: false,
      showVideo: false
    }
  }

  playVideo(state) {
    if (this.props.item.modelVideo) {
      this.setState({showVideo: state});

      if (state) {
        gsap.to('.itemImage' + this.props.index, {
          opacity: 0,
          duration: 0.5
        })
      } else if (this.state.videoPlaying === true && state === false) {
        gsap.to('.itemVideo' + this.props.index, {
          opacity: 0,
          duration: 0.5
        })
      }
    }

    this.setState({videoPlaying: state})
  }

  autoplayVideo() {
    if (document.getElementById(this.props.category + '_' + this.props.index)) {
      var element = document.getElementById(this.props.category + '_' + this.props.index);
      var rect = element.getBoundingClientRect();
      var html = document.documentElement;

      if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || html.clientHeight) && rect.right <= (window.innerWidth || html.clientWidth) && window.innerWidth <= 768) {
        setTimeout(() => {
          if (!this.state.showVideo) {
            this.playVideo(true)

            setTimeout(() => {
              this.playVideo(false)
            }, 15000);
          }
        }, 4000);
      } else {
        if (this.state.showVideo) {
          this.playVideo(false)
        }
      }
    }
  }

  componentDidMount() {
    document.getElementById('navbar').style.display = 'flex';
    
    this.autoplayVideo();

    window.addEventListener('scroll', (e) => {
      this.autoplayVideo();
    })
  }

  componentWillUnmount() {
  }

  render() {
    var assets = require.context('../../assets', true, /\.(mp4|m4v|jpg)$/);

    return (
      <div id={this.props.category + '_' + this.props.index} className={Styles.itemContainer}>
        {
          (this.state.showVideo && this.props.item.modelVideo) ? (
            <video key={`gridItemVideo` + this.state.index} onMouseOut={() => {if (window.innerWidth >= 768) {this.playVideo(false)}}} className={`${'itemVideo' + this.props.index} ${(this.props.category === 'headbands') ? (Styles.headbandVideo) : (Styles.itemVideo)}`} autoBuffer playsInline muted="muted" autoPlay loop type="video/mp4">
              <source src={'https://siyousenassets.s3-us-west-2.amazonaws.com/assets/' + this.props.category + '/' + this.props.index + '/' + this.props.item.modelVideo}></source>
            </video>
          ) : (
            <ClickNHold time={3} onClickNHold={(t, target) => {this.playVideo(true)}} onEnd={(e, enough) => {this.playVideo(false)}}>
              <img onMouseOver={() => {this.playVideo(true)}} onContextMenu={(e) => {e.preventDefault(); return false;}} className={`${'itemImage' + this.props.index} ${(this.props.category === 'headbands') ? (Styles.headbandImage) : (Styles.itemImage)}`} src={assets('./' + this.props.category + '/' + this.props.index + '/image.jpg').default} alt={this.props.item.title} />
            </ClickNHold>
          )
        }
        <div className={Styles.infoContainer} onClick={() => {this.props.history.push('/' + this.props.category + '/' + this.props.index)}}>
          <p className={Styles.itemName}>{this.props.item.title}</p>
          <div className={Styles.priceContainer}>
            <p className={(this.props.category === 'outerwear') ? (Styles.outerwearPrice) : (Styles.itemPrice)}>&#36;{this.props.item.price}</p>
            {
              (this.props.category === 'outerwear' && this.props.item.reproductionPrice !== '-') ? (
                <p className={Styles.reproductionPrice}>&#36;{this.props.item.reproductionPrice}</p>
              ) : (null)
            }
          </div>
        </div>
      </div>
    )
  }
}

export default GridItem;
