import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Styles from './app.module.css';
import LandingScreen from '../landingScreen';
import ProductScreen from '../productScreen';
import OuterwearScreen from '../outerwearScreen';
import PantsScreen from '../pantsScreen';
import CropTopsScreen from '../cropTopsScreen';
import HeadbandsScreen from '../headbandsScreen';

function App() {
  let history = useHistory();

  return (
    <div>
      <div id="navbar" className={Styles.navbar}>
        <video className={Styles.textVideo} playsInline muted="muted" autoPlay loop type="video/mp4" onClick={() => {history.push('/')}}>
          <source src={'https://siyousenassets.s3-us-west-2.amazonaws.com/assets/videos/siyousen_text.mp4'}></source>
        </video>
        <div className={`row m-0 ${Styles.linksContainer}`}>
          <button onClick={() => {history.push('/outerwear')}} className={Styles.link}>Outerwear</button>
          <button onClick={() => {history.push('/pants')}} className={Styles.link}>Pants</button>
          <button onClick={() => {history.push('/headbands')}} className={Styles.link}>Headbands</button>
          <button onClick={() => {history.push('/croptops')}} className={Styles.link}>Crop Tops</button>
          <button onClick={() => {window.location.href = 'https://siyousen.com/pages/reproductions-page'}} className={Styles.link}>Custom Requests</button>
        </div>
      </div>
      <Switch>
        {/* landing screen */}
        <Route path="/" component={LandingScreen} exact />

        {/* cropTops screen */}
        <Route path="/croptops" component={CropTopsScreen} exact />

        {/* headbands screen */}
        <Route path="/headbands" component={HeadbandsScreen} exact />

        {/* outerwear screen */}
        <Route path="/outerwear" component={OuterwearScreen} exact />

        {/* pants screen */}
        <Route path="/pants" component={PantsScreen} exact />

        {/* product screen */}
        <Route path="/:category/:id" component={ProductScreen} exact />
      </Switch>
    </div>
  );
}

export default App;
