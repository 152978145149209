import React from 'react';
import Styles from './landingScreen.module.css';
import gsap from 'gsap';

class LandingScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTextVideo: false,
      switchComponent: '',
      redirectLink: '',
      playCategoryVideo: '',
      categoryVideo: '',
      mobile: (window.innerWidth <= 900) ? (true) : (false)
    }
  }

  categoryScreen(screenName) {
    this.setState({
      playCategoryVideo: true,
      showTextVideo: false,
      redirectLink: '/' + screenName.toLowerCase()
    })

    var videoAssets = 'https://siyousenassets.s3-us-west-2.amazonaws.com/assets/videos/';

    this.setState({categoryVideo: (window.innerWidth <= 900) ? (videoAssets + screenName + '_rising_mobile.mp4') : (videoAssets + screenName + '_rising.mp4')})
  }

  switchScreen() {
    gsap.to('.mainVideoPlayer', {
      opacity: 0,
      duration: 0.01
    })

    gsap.to('.categoryVideoPlayer', {
      opacity: 0,
      duration: 0.5,
      onComplete: () => {
        this.props.history.push(this.state.redirectLink)
      }
    })
  }

  async componentDidMount() {
    document.getElementById('navbar').style.display = 'none';
    
    await this.setState({mobile: (window.innerWidth <= 900) ? (true) : (false)})

    await window.addEventListener('resize', () => {
      if ((window.innerWidth <= 900 && this.state.mobile !== true) || (window.innerWidth > 900 && this.state.mobile !== false)) {
        this.setState({showTextVideo:  false})
      }
      
      this.setState({mobile: (window.innerWidth <= 900) ? (true) : (false)})
    });
  }

  componentWillUnmount() {
  }

  render() {
    var videoAssets = 'https://siyousenassets.s3-us-west-2.amazonaws.com/assets/videos/';

    return (
      <div className={Styles.mainContainer}>
        <video key={`landingVideo` + this.state.mobile} className={`mainVideoPlayer ${Styles.clothesVideo}`} onEnded={() => {this.setState({showTextVideo: true})}} playsInline muted="muted" autoPlay type="video/mp4">
          <source src={(this.state.mobile) ? (videoAssets + 'clothes_falling_mobile.mp4') : (videoAssets + 'clothes_falling.mp4')}></source>
        </video>
        {
          (this.state.showTextVideo) ? (
            <div className={Styles.textVideoContainer}>
              <video key={`textVideo` + this.state.showTextVideo} className={Styles.textVideo} playsInline muted="muted" autoPlay loop type="video/mp4">
                <source src={videoAssets + 'siyousen_text.mp4'}></source>
              </video>
              <div className={`row m-0 ${Styles.linksContainer}`}>
                <button onClick={(e) => {e.preventDefault(); this.categoryScreen('outerwear')}} className={Styles.link}>Outerwear</button>
                <button onClick={(e) => {e.preventDefault(); this.categoryScreen('pants')}} className={Styles.link}>Pants</button>
                <button onClick={(e) => {e.preventDefault(); this.categoryScreen('headbands')}} className={Styles.link}>Headbands</button>
                <button onClick={(e) => {e.preventDefault(); this.categoryScreen('cropTops')}} className={Styles.link}>Crop Tops</button>
                <button onClick={(e) => {e.preventDefault(); window.location.href = 'https://siyousen.myshopify.com/pages/custom-orders'}} className={Styles.link}>Custom Requests</button>
              </div>
            </div>
          ) : (null)
        }
        {
          (this.state.playCategoryVideo) ? (
            <video key={`categoryVideo` + this.state.playCategoryVideo} className={`categoryVideoPlayer ${Styles.categoryVideo}`} onEnded={(e) => {this.switchScreen()}} playsInline muted="muted" autoPlay type="video/mp4">
              <source src={this.state.categoryVideo}></source>
            </video>
          ) : (null)  
        }
      </div>
    )
  }
}
export default LandingScreen;
