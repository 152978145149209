import React from 'react';
import Styles from './productScreen.module.css';
import { Carousel } from 'react-bootstrap';
import ReproductionBackground from '../../assets/images/reproduction-button.svg';
import NoImagesImage from '../../assets/images/no-images.jpg';

class ProductScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state= {
      flatImages: '',
      wornImages: '',
      modelVideo: '',
      productData: require.context('../../data', true, /.*/)('./' + this.props.match.params.category + '.json')[this.props.match.params.id],
      mobile: (window.innerWidth < 768) ? (true) : (false),
      zoomImage: false,
      zoomImageSrc: '',
      dimensions: ''
    }
  }

  zoomImage(e) {
    this.setState({
      zoomImageSrc: e.target.src,
      zoomImage: true
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    document.getElementById('navbar').style.display = 'flex';
    
    document.title = this.state.productData.title;

    var productAssets = require.context('../../assets', true, /.*/);
    var flatImages = [];
    var wornImages = [];
    var productCategory = this.props.match.params.category;
    var productID = this.props.match.params.id;
    var imageAssets = require.context('../../assets/images', true, /.*/);

    for(var flatIndex = 0; flatIndex < this.state.productData.flatImagesCount; flatIndex++) {
      flatImages.push(
        <Carousel.Item key={flatIndex}>
          <img onClick={(e) => {this.zoomImage(e)}} className={Styles.carouselImage} src={productAssets('./' + productCategory + '/' + productID + '/flat/' + (flatIndex + 1) + '.jpg').default} alt="flat" />
        </Carousel.Item>
      )
    }

    if (this.state.productData.wornImagesCount === 0) {
      wornImages.push(
        <Carousel.Item key="1">
          <img onClick={(e) => {this.zoomImage(e)}} className={Styles.carouselImage} src={NoImagesImage} alt="worn" />
        </Carousel.Item>
      )
    } else {
      for(var wornIndex = 0; wornIndex < this.state.productData.wornImagesCount; wornIndex++) {
        wornImages.push(
          <Carousel.Item key={wornIndex}>
            <img onClick={(e) => {this.zoomImage(e)}} className={Styles.carouselImage} src={productAssets('./' + productCategory + '/' + productID + '/worn/' + (wornIndex + 1) + '.jpg').default} alt="worn" />
          </Carousel.Item>
        )
      }
    }

    this.setState({
      flatImages: flatImages,
      wornImages: wornImages,
      modelVideo: (this.state.productData.modelVideo) ? ('https://siyousenassets.s3-us-west-2.amazonaws.com/assets/' + productCategory + '/' + productID + '/' + this.state.productData.modelVideo) : (null)
    })

    window.addEventListener('resize', () => {
      this.setState({
        mobile: (window.innerWidth <= 768) ? (true) : (false)
      })
    });

    if (this.props.match.params.category === 'outerwear' || this.props.match.params.category === 'cropTops') {
      this.setState({
        dimensions: (typeof(this.state.productData.dimensions) === 'object') ? (
          <div className={`row ${Styles.dimensionsContainer}`}>
            <div className={`row ${Styles.dimensionsContent}`}>
              <div className={Styles.dimension}>
                <img src={imageAssets('./shirt-shoulder.jpg').default} alt="shoulder" />
                <p>{this.state.productData.dimensions.shoulder}</p>
              </div>
              <div className={Styles.dimension}>
                <img src={imageAssets('./shirt-chest.jpg').default} alt="chest" />
                <p>{this.state.productData.dimensions.chest}</p>
              </div>
            </div>
            <div className={`row ${Styles.dimensionsContent}`}>
              <div className={Styles.dimension}>
                <img src={imageAssets('./shirt-height.jpg').default} alt="length" />
                <p>{this.state.productData.dimensions.length}</p>
              </div>
              <div className={Styles.dimension}>
                <img src={imageAssets('./shirt-sleeve.jpg').default} alt="sleeve" />
                <p>{this.state.productData.dimensions.sleeve}</p>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center">{this.state.productData.dimensions}</p>
        )
      })
    } else if ((this.props.match.params.category === 'pants')) {
      this.setState({
        dimensions: (typeof(this.state.productData.dimensions) === 'object') ? (
          <div className={`row ${Styles.dimensionsContainer}`}>
            <div className={Styles.dimension}>
              <img src={imageAssets('./pant-waist.jpg').default} alt="waist" />
              <p>{this.state.productData.dimensions.waist}</p>
            </div>
            <div className={Styles.dimension}>
              <img src={imageAssets('./pant-length.jpg').default} alt="length" />
              <p>{this.state.productData.dimensions.length}</p>
            </div>
          </div>
        ) : (
          <p className="text-center">{this.state.productData.dimensions}</p>
        )
      })
    } else if (this.props.match.params.category === 'headbands') {
      this.setState({
        dimensions: <div className={`row ${Styles.dimensionsContainer}`}>
          <div className={`${Styles.dimension} ${Styles.headbandDimension}`}>
            <img src={imageAssets('./headband-circumference.jpg').default} alt="circumference" />
            <p>{this.state.productData.dimensions}</p>
          </div>
        </div>
      })
    }
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className={Styles.mainContainer}>
        <div className="row">
          {
            (this.state.mobile) ? (
              <div className={Styles.sectionContainer}>
                <div className={Styles.infoContainer}>
                  <p className={Styles.itemName}>{this.state.productData.title}</p>
                  <div className={Styles.priceContainer}>
                    <p className={(this.props.match.params.category === 'outerwear') ? (Styles.outerwearPrice) : (Styles.itemPrice)}>&#36;{this.state.productData.price}</p>
                    {
                      (this.props.match.params.category === 'outerwear') ? (
                        <p className={Styles.reproductionPrice}>{(this.state.productData.reproductionPrice === '-') ? ('-') : ('$' + this.state.productData.reproductionPrice)}</p>
                      ) : (null)
                    }
                  </div>
                </div>
                <Carousel indicators={false}>
                  {this.state.flatImages}
                </Carousel>
                <Carousel className={Styles.wornImagesCarousel} indicators={false}>
                  {this.state.wornImages}
                </Carousel>
                <p className="font-weight-bold mt-5">Dimensions</p>
                {this.state.dimensions}
              </div>
            ) : (
              <>
                <div className={Styles.sectionContainer}>
                  <Carousel indicators={false}>
                    {this.state.flatImages}
                  </Carousel>
                  <p className="font-weight-bold mt-5">Dimensions</p>
                  {this.state.dimensions}
                </div>
                <div className={Styles.sectionContainer}>
                  <div className={Styles.infoContainer}>
                    <p className={Styles.itemName}>{this.state.productData.title}</p>
                    <div className={Styles.priceContainer}>
                      <p className={(this.props.match.params.category === 'outerwear') ? (Styles.outerwearPrice) : (Styles.itemPrice)}>&#36;{this.state.productData.price}</p>
                      {
                        (this.props.match.params.category === 'outerwear') ? (
                          <p className={Styles.reproductionPrice}>{(this.state.productData.reproductionPrice === '-') ? ('-') : ('$' + this.state.productData.reproductionPrice)}</p>
                        ) : (null)
                      }
                    </div>
                  </div>
                  <Carousel className={Styles.wornImagesCarousel} indicators={false}>
                    {this.state.wornImages}
                  </Carousel>
                </div>
              </>
            ) 
          }
        </div>
        <div className={Styles.detailsContainer}>
          {
            (this.state.modelVideo) ? (
              <video key={`gridItemVideo` + this.state.index} className={Styles.modelVideo} playsInline muted="muted" autoPlay loop type="video/mp4">
                <source src={this.state.modelVideo}></source>
              </video>
            ) : (
              <div className={Styles.comingSoonContainer}>
                3D Video Coming Soon!
              </div>
            )
          }
          <div className={Styles.buttonsContainer}>
            <button className={Styles.buyNowBtn} onClick={() => {window.open(this.state.productData.externalLink)}}>
              <p>Buy Now!</p>
            </button>
            <div className={Styles.priceTag}>
              <p>&#36;{this.state.productData.price}</p>
            </div>
          </div>
          <div className={Styles.reproductionBtn}>
            <img src={ReproductionBackground} alt="buy reproduction" />
            <p onClick={() => {window.location.href = 'https://siyousen.myshopify.com/collections/reproductions'}} className={Styles.buyReproduction}>Buy Reproduction!</p>
            <p className={Styles.reproductionInfo}>What is reproduction?</p>
          </div>
        </div>
        {
          (this.state.zoomImage) ? (
            <div className={`zoomImageContainer ${Styles.zoomImageContainer}`} onClick={(e) => {this.setState({zoomImage: (e.target.classList.contains('zoomImageContainer') || e.target.classList.contains('closeImageIcon')) ? (false) : (true)})}}>
              <button type="button" aria-label="Close">
                <span className="closeImageIcon" aria-hidden="true">&times;</span>
              </button>
              <img src={this.state.zoomImageSrc} alt={this.state.productData.title} />
            </div>
          ) : (null)  
        }
        <div className={Styles.descriptionContainer}>
          <p className="font-weight-bold">Product Description</p>
          {
            Object.entries(this.state.productData.description.split('\n')).map(([key, description]) => {
              return (<p key={key}>{description}</p>)
            })
          }
        </div>
      </div> 
    )
  }
}
export default ProductScreen;
