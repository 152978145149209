import React from 'react';
import Styles from './outerwearScreen.module.css';
import OuterwearData from '../../data/outerwear.json';
import GridItem from '../gridItem';
import gsap from 'gsap';

class OuterwearScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: ''
    }
  }

  componentDidMount() {
    document.getElementsByTagName('title')[0].innerHTML = 'Outerwear | Siyousen';

    gsap.to('.gridContainer', {
      opacity: 1,
      duration: 0.5
    })

    this.setState({
      items: OuterwearData.map((item, index) => {
        return (<GridItem key={index} index={index} item={item} category="outerwear" history={this.props.history} />)
      })
    })
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className={Styles.mainContainer}>
        <div className={`row m-0 gridContainer ${Styles.gridContainer}`}>
          {this.state.items}
        </div>
      </div>
    )
  }
}

export default OuterwearScreen;
